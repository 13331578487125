<template>
  <el-dialog
    class="open-vip-dialog"
    :title="isCredit ? $t('Vip.text12') : $t('Vip.openVip')"
    :visible.sync="localVisible"
    width="542px"
    :before-close="handleClose"
    :append-to-body="true"
  >
    <div class="open-vip-dialog-content" v-loading="orderLoading">
      <div class="title" v-if="orderInfo && !isCredit">
        {{ orderInfo.order_name }}
      </div>
      <div class="credit-list" v-if="isCredit">
        <div
          class="credit-item"
          @click="selectcredit(item)"
          v-for="(item, index) of creditList"
          :key="index"
          :class="{ active: currentInfo.credit_add === item.credit_add }"
        >
          {{ item.credit_add }} {{ $t('Vip.credit') }}
        </div>
      </div>
      <div class="pay-list">
        <div>{{ $t('Vip.Payment') }}</div>
        <div class="right" v-loading="urlLoading">
          <el-radio-group v-model="payType">
            <el-radio
              class="radio"
              :label="'wechat'"
              v-if="$i18n.locale == 'ch'"
              ><i class="f-icon icon-weixin pay-icon" />{{
                $t('Vip.wechatPay')
              }}
            </el-radio>
            <el-radio
              class="radio"
              :label="'alibaba'"
              v-if="$i18n.locale == 'ch'"
              ><i class="f-icon icon-zhifubao pay-icon" />{{ $t('Vip.aliPay') }}
            </el-radio>
            <el-radio
              class="radio"
              :label="'stripe'"
              v-if="$i18n.locale == 'en'"
            >
              <i
                class="f-icon icon-yinhangka"
                style="color:#ffc06a; margin-right: 12px;"
              />Stripe
            </el-radio>
          </el-radio-group>
          <div class="qrcode-content">
            <template v-if="payType === 'alibaba'">
              <div class="qrcode-content-text alibaba">
                <div class="qrcode-content-num" v-if="!isHelp">
                  <div v-if="currentInfo.money_old" class="old">
                    ￥{{ currentInfo.money_old }}
                  </div>
                  <div>￥{{ currentInfo.money }}</div>
                </div>
                <template v-if="isHelp">
                  <div>{{ $t('Vip.qrcodeHelp') }}</div>
                  <img :src="helperInfo.site_val" />
                  <a
                    class="buy-link-text"
                    :href="alipayUrl"
                    target="_blank"
                    @click="clickPay"
                    >{{ $t('Vip.payAgain') }}</a
                  >
                </template>
                <template v-else-if="isClickPay">
                  <div class="tips-text">{{ $t('Vip.openNewPay') }}</div>
                  <div class="btn-list">
                    <div class="buy-link-text" @click="clickHelp">
                      {{ $t('Vip.payError') }}
                    </div>
                    <div class="buy-link" @click="getPayResult(true)">
                      {{ $t('Vip.paySuccess') }}
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div>{{ $t('Vip.jumpAlipay') }}</div>
                  <a
                    class="buy-link"
                    :href="alipayUrl"
                    target="_blank"
                    @click="clickPay"
                    >{{ $t('Vip.goToPay') }}</a
                  >
                  <div class="tips">{{ $t('Vip.noAutoPay') }}</div>
                </template>
              </div>
            </template>
            <template v-else-if="payType === 'wechat'">
              <div class="qrcode-wrap">
                <div
                  class="qrcode-block"
                  :class="{ 'qrcode-overtime': overtime }"
                >
                  <div id="qrcode" ref="qrcode" class="qrcode"></div>
                </div>
                <i
                  class="f-icon icon-loading"
                  v-if="overtime"
                  @click="createUrlQrcode"
                />
              </div>
              <div class="qrcode-content-text">
                <div class="qrcode-content-num">
                  <div>￥{{ currentInfo.money }}</div>
                  <div v-if="currentInfo.money_old" class="old">
                    ￥{{ currentInfo.money_old }}
                  </div>
                </div>
                <div>{{ $t('Vip.openPhoneWechat') }}</div>
                <div>{{ $t('Vip.qrcodePay') }}</div>
                <div class="overtime">
                  <span v-if="overtime"
                    ><i class="f-icon icon-shijian" />{{
                      $t('Vip.payTimeout')
                    }}</span
                  >
                </div>
                <div class="tips">{{ $t('Vip.noAutoPay') }}</div>
              </div>
            </template>
            <template v-else>
              <div class="card-wrap">
                <div>
                  <div class="label">Card number</div>
                  <div id="card-number" class="ipt"></div>
                </div>
                <div class="card-row">
                  <div>
                    <div class="label">Exp. date</div>
                    <div id="card-expiry" class="ipt"></div>
                  </div>
                  <div>
                    <div class="label">CVC</div>
                    <div id="card-cvc" class="ipt"></div>
                  </div>
                </div>

                <div class="card-btn">
                  <a class="buy-link" @click="stripePay">
                    {{ $t('Vip.goToPay') }}
                  </a>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import {
  apiVipPayAdd,
  apiVipWxUnify,
  apiVipAliUnify,
  apiVipPayResult
} from '@/api/api.js'
import { apiStripPay } from '@/api/userInfo'
import QRCode from 'qrcodejs2'
import emitter from '@/utils/emitter'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    info: {
      type: Object,
      default: () => {
        return {}
      }
    },
    creditList: {
      type: Array
    }
  },
  data() {
    return {
      currentInfo: this.info,
      payType: this.$i18n.locale == 'ch' ? 'wechat' : 'stripe',
      showDialog: true,
      localVisible: this.visible,
      qrcode: '',
      qr: null,
      orderInfo: null,
      orderLoading: false,
      urlLoading: false,
      overtime: false,
      overtimeTimer: null,
      isClickPay: false,
      isHelp: false,
      //判断是否已经点击了支付
      showType: false
    }
  },
  mounted() {
    this.createOrder()

    if (this.payType === 'stripe') {
      this.$nextTick(() => {
        this.initStripe()
      })
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
    clearTimeout(this.overtimeTimer)

    this.cardNumber && this.cardNumber.destroy()
    this.cardExpiry && this.cardExpiry.destroy()
    this.cardCvc && this.cardCvc.destroy()
  },
  watch: {
    payType() {
      this.createUrlQrcode()

      if (this.payType === 'stripe') {
        this.$nextTick(() => {
          this.initStripe()
        })
      }
    }
  },
  computed: {
    ...mapState('app', {
      helperInfo: (state) => state.helperInfo
    }),
    isCredit() {
      return this.currentInfo.payType === 2
    },
    alipayUrl() {
      return this.orderInfo
        ? `${process.env.API_HTTP_ADDRESS}api/pay/ali-h5-unify?order_id=${this.orderInfo.order_id}`
        : ''
    }
  },
  methods: {
    ...mapActions('app', ['GetUserInfo']),
    clickHelp() {
      this.isHelp = true
    },
    clickPay() {
      this.showType = true
      this.isHelp = false
      this.isClickPay = true
    },
    selectcredit(item) {
      if (item.credit_add === this.currentInfo.credit_add) {
        return
      }
      this.currentInfo = item
      this.createOrder()
    },
    handleClose() {
      this.$emit('closeDialog')
    },
    createOrder() {
      this.orderLoading = true
      const { payType, goods_id, money } = this.currentInfo
      apiVipPayAdd({
        goods_type: payType,
        goods_id: goods_id,
        money_total: money
      })
        .then((res) => {
          if (res.code !== 200) {
            return
          }
          this.orderInfo = res.data
          this.createUrlQrcode()
        })
        .finally(() => {
          this.orderLoading = false
        })
    },
    createUrlQrcode() {
      clearInterval(this.timer)
      clearTimeout(this.overtimeTimer)
      this.overtime = false
      this.isHelp = false
      this.isClickPay = false
      this.closeCode()
      if (this.payType === 'alibaba') {
        this.timer = setInterval(this.getPayResult, 3000)
      } else {
        this.urlLoading = true
        apiVipWxUnify({
          order_id: this.orderInfo.order_id
        })
          .then((res) => {
            if (res.code !== 200) {
              return
            }
            this.qrcode = res.data.qr_code_url
            this.$nextTick(() => {
              this.createQrcode()
            })
            this.overtimeTimer = setTimeout(this.overtimeCode, 180000)
            this.timer = setInterval(this.getPayResult, 3000)
          })
          .finally(() => {
            this.urlLoading = false
          })
      }
    },
    // 生成二维码
    createQrcode() {
      this.qr = new QRCode('qrcode', {
        width: 130,
        height: 130,
        text: this.qrcode
      })
    },
    closeCode() {
      if (this.$refs.qrcode) {
        this.$refs.qrcode.innerHTML = ''
      }
    },
    overtimeCode() {
      this.overtime = true
    },
    getPayResult(check) {
      // console.log('======= get pay result')
      apiVipPayResult({
        order_id: this.orderInfo.order_id
      }).then((res) => {
        if (
          this.showType &&
          this.payType == 'alibaba' &&
          !res.data.pay_status
        ) {
          this.$notify({
            title: this.$t('Vip.tipTitle'),
            message: this.$t('Vip.payFail'),
            type: 'warning'
          })
          this.handleClose()
          clearInterval(this.timer)
          return
        }
        // console.log(
        //   '======= get pay result:',
        //   JSON.stringify(this.orderInfo),
        //   JSON.stringify(res),
        //   res.data.pay_status
        // )
        if (res.code !== 200) {
          return
        }
        if (res.data.pay_status) {
          clearInterval(this.timer)
          this.handleClose()
          this.$emit('successDialog')
          this.$layer.msg(
            `<div class='success-icon'></div><div>${this.$t(
              'Vip.paySuccess'
            )}</div>`
          )
          setTimeout(() => {
            window.location.reload()
          }, 1300)
        } else if (check) {
          clearInterval(this.timer)
          this.handleClose()
          this.$layer.msg(`<div>${this.$t('Vip.payFail')}</div>`)
        }

        this.GetUserInfo()
      })
    },

    // stripe
    initStripe() {
      const stripeElements = this.$stripe.elements()
      const style = {
        base: {
          color: 'black',
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '14px',
          lineHeight: '46px',
          backgroundcolor: '#fff',
          '::placeholder': {
            color: '#aab7c4'
          }
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a'
        }
      }
      this.cardNumber = stripeElements.create('cardNumber', { style })
      this.cardNumber.mount('#card-number')
      this.cardExpiry = stripeElements.create('cardExpiry', { style })
      this.cardExpiry.mount('#card-expiry')
      this.cardCvc = stripeElements.create('cardCvc', { style })
      this.cardCvc.mount('#card-cvc')
    },
    async stripePay() {
      const { data } = await apiStripPay({
        order_id: this.orderInfo.order_id
      })
      const result = await this.$stripe.confirmCardPayment(data.clientSecret, {
        payment_method: {
          card: this.cardNumber
        }
      })
      if (result.error) {
        // 支付失败
        this.$message.error(result.error.message || 'Pay for failure')
      } else {
        // 支付成功
        this.$message.success('Pay for success')
        setTimeout(() => {
          window.location.reload()
        }, 1300)
      }
    }
  }
}
</script>

<style lang="scss">
.open-vip-dialog {
  .el-dialog__header {
    display: flex;
    margin: 0 16px;
    justify-content: space-between;
    padding: 9px 0;
    border-bottom: 1px solid #ebebf2;
    align-items: center;

    .el-dialog__headerbtn {
      position: inherit;
    }

    .el-dialog__title {
      font-size: 14px;
      font-weight: bold;
      color: #a4afb7;
    }
  }

  .open-vip-dialog-content {
    margin: 40px 0;
  }

  .el-radio {
    margin-right: 44px;
  }

  .el-radio__inner {
    border: 1px solid #c3daff;
  }

  .el-dialog__body {
    margin-top: -30px;
  }

  .el-radio__input.is-checked .el-radio__inner {
    border-color: #3b86ff;
    background: #fff;

    &::after {
      width: 8px;
      height: 8px;
      background: #3b86ff;
    }
  }

  .el-radio__input.is-checked + .el-radio__label {
    color: #4d4f5c;
  }

  .el-radio__label {
    color: #a4afb7;
  }

  .qrcode-content {
    display: flex;
    width: 360px;
    padding: 30px 40px;
    background: #fafafa;
    border-radius: 5px;
    box-sizing: border-box;
    margin-top: 20px;
  }

  .qrcode-content-text {
    &.alibaba {
      text-align: center;
      width: 100%;

      .tips-text {
        margin-top: 17px;
      }

      .qrcode-content-num {
        display: block;
        height: 58px;
        margin: -10px 0 10px;

        .old {
          margin: 0 0 10px 0;
        }
      }

      img {
        margin: 7px 0 17px;
      }
    }
  }

  .buy-link {
    display: inline-block;
    color: #fff;
    background: #3b86ff;
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
    text-align: center;
    width: 160px;
    height: 32px;
    line-height: 32px;
    text-decoration: none;
    margin: 7px 0 9px;

    &:hover {
      opacity: 0.8;
    }
  }

  .buy-link-text {
    display: block;
    width: 114px;
    cursor: pointer;
    margin: 0 auto;
    color: #3b86ff;
    text-decoration: none;
  }

  .btn-list {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 20px;
    margin-top: 30px;

    .buy-link {
      width: 114px;
    }
  }

  .qrcode-content-num {
    display: flex;
    margin: 8px 0 9px;
    font-size: 20px;
    color: #3b86ff;
    align-items: center;
  }

  .old {
    margin: 0 0 0 15px;
    font-size: 14px;
    color: #bcbccb;
    text-decoration: line-through;
  }

  .tips {
    font-size: 12px;
    font-weight: 400;
    color: #d5d5d5;
  }

  .overtime {
    margin: 9px 0 8px;
    color: #ff606d;
    font-size: 12px;
    height: 17px;

    i {
      font-size: 12px;
      margin-right: 5px;
    }
  }

  .qrcode-wrap {
    position: relative;

    i {
      position: absolute;
      color: #fff;
      font-size: 26px;
      top: 50%;
      left: 50%;
      margin: -13px 0 0 -26px;
      cursor: pointer;
    }
  }

  .qrcode-block {
    position: relative;
    width: 142px;
    height: 142px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    margin-right: 23px;
    border-radius: 5px;

    &.qrcode-overtime {
      filter: brightness(0.6);
    }
  }

  .icon-zhifubao {
    color: #3b86ff;
  }

  .icon-weixin {
    color: #29bc1e;
  }

  .qrcode {
    width: 130px;
    height: 130px;
  }

  .pay-list {
    display: flex;
  }

  .title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 17px;
  }

  .right {
    margin-left: 15px;
  }

  .pay-icon {
    font-size: 20px;
    margin-right: 5px;
    vertical-align: bottom;
  }

  .credit-list {
    display: flex;
    margin-bottom: 20px;

    .credit-item {
      width: 100px;
      height: 32px;
      margin-right: 29px;
      line-height: 32px;
      font-size: 18px;
      background: #ebebf2;
      border-radius: 5px;
      text-align: center;
      cursor: pointer;

      &.active {
        color: #ffffff;
        background: #3b86ff;
      }
    }
  }

  .card-wrap {
    flex: 1;
    .label {
      font-size: 16px;
      color: #1f1f40;
      padding-bottom: 10px;
    }
    .card-row {
      display: flex;
      align-items: center;
      margin-top: 20px;
      > div {
        flex: 1;
        &:nth-child(n + 2) {
          margin-left: 20px;
        }
      }
    }

    .ipt {
      border: 1px solid #c4cdd5;
      padding: 0 12px;
      border-radius: 8px;
      min-height: 46px;
      flex: 1;
    }
    .card-btn {
      margin-top: 20px;
      text-align: center;
    }
  }
}
</style>
