<template>
  <el-dialog
    class="free-vip-dialog"
    :title="$t('Vip.memberTitle')"
    :visible.sync="localVisible"
    width="410px"
    :before-close="handleClose"
    :append-to-body="true"
  >
    <div class="free-vip-dialog-content">
      <p>{{ $t('Vip.memeberTip') }}</p>
      <img :src="helperInfo.site_val" />
      <p class="wechat">{{ helperInfo.remark }}</p>
    </div>
  </el-dialog>
</template>

<script>
import { mapState } from 'vuex'
import { TIPS_LIST } from '../utils'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('app', {
      helperInfo: (state) => state.helperInfo
    })
  },
  data() {
    return {
      showDialog: true,
      localVisible: this.visible,
      list: TIPS_LIST
    }
  },
  methods: {
    handleClose() {
      this.$emit('closeDialog')
    }
  }
}
</script>

<style lang="scss">
.free-vip-dialog {
  text-align: center;
  font-size: 14px;
  color: #1f1f40;
  img {
    width: 109px;
    height: 109px;
  }
  p {
    line-height: 22px;
    text-align: left;
  }
  .wechat {
    text-align: center;
    color: #9e9e9e;
  }
  .el-dialog__header {
    display: flex;
    margin: 0 16px;
    justify-content: space-between;
    padding: 9px 0;
    border-bottom: 1px solid #ebebf2;
    align-items: center;
    .el-dialog__headerbtn {
      position: inherit;
    }
    .el-dialog__title {
      font-size: 14px;
      font-weight: bold;
      color: #a4afb7;
    }
  }
}
</style>
