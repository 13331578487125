<template>
  <div class="vip-new">
    <div class="vip-new-content">
      <div
        class="vip-new-user"
        :class="{ vip: userInfo.vip && userInfo.vip.code != 0 }"
      >
        <img
          class="header"
          v-if="userInfo.headimgurl"
          :src="userInfo.headimgurl"
        />
        <div class="vip-new-user-text">
          <div>
            <div class="name">
              <span>{{ userInfo.name }}</span>
              <img
                v-if="userInfo.vip && userInfo.vip.code != 0"
                class="vip-img"
                :src="require('@/assets/vip-new/vip-icon.png')"
                alt
              />
            </div>
            <div class="msg">
              <span class="text">
                <i class="f-icon icon-diamond" />
                <span v-if="userInfo.vip && userInfo.vip.code != 0"
                  >{{ i18n.Vip.surplus }}{{ userInfo.vip_day_last
                  }}{{ i18n.Vip.day }}</span
                >
                <span v-else>{{ i18n.Vip.text }}</span>
              </span>
              <span class="text">
                <i class="f-icon icon-quan" />
                <span>{{ i18n.Vip.text2 }} {{ userInfo.credit }}</span>
              </span>
            </div>
          </div>
        </div>
        <div class="btns">
          <div class="vip-new-user-record" @click="goRecord">
            {{ i18n.Vip.text3 }}
          </div>
          <div class="vip-new-user-activate">
            <span>{{ i18n.Vip.text4 }}</span>
            <span class="btn vip-new-user-activate-btn" @click="showVip">
              {{ i18n.Vip.text5 }}
            </span>
          </div>
        </div>
      </div>
      <div class="vip-new-free" v-if="userInfo.can_first_free_get">
        <i class="f-icon icon-close" @click="closeFree" />
        <img :src="require('@/assets/vip-new/vip-header.png')" />
        <div>
          <div class="title">
            {{ i18n.Vip.text6 }}
          </div>
          <p>{{ i18n.Vip.text7 }}</p>
          <div class="btn free-btn" @click="showFree">{{ i18n.Vip.text8 }}</div>
        </div>
      </div>
      <div class="vip-new-buy-list">
        <h3>{{ i18n.Vip.title }}</h3>
        <div class="list">
          <div class="vip-list">
            <div class="title">{{ i18n.Vip.text9 }}</div>
            <div class="buy-list">
              <div
                v-for="(item, index) of monthList"
                :key="index"
                class="buy-list-item"
                :class="{ active: item.is_like }"
              >
                <div v-if="item.is_like" class="active-name">{{ i18n.Vip.text10 }}</div>
                <div class="time">{{ item.name }}</div>
                <div class="old">
                  <span v-if="item.money_old">￥{{ item.money_old }}</span>
                </div>
                <div class="new">
                  <span class="unit">￥</span
                  ><span class="num">{{ item.money }}</span>
                </div>
                <ul class="text-list">
                  <li>{{ monthTextList[0] }}</li>
                  <li>{{ monthTextList[1] }}</li>
                  <li>{{ monthTextList[2] }}</li>
                  <!--
                  <li>
                    {{ monthTextList[3] }}<span>{{ item.credit_add }}{{ i18n.Vip.text11 }}</span>
                  </li>
                  -->
                </ul>
                <div class="btn buy-btn" @click="goBuy(item)">{{ i18n.Vip.purchase }}</div>
              </div>
            </div>
          </div>
          <!--
          <div class="vip-list dot-list">
            <div class="title">
             {{ i18n.Vip.text12 }}
              <i class="f-icon icon-question-circle" @click="showTips" />
            </div>
            <div class="buy-list">
              <div
                class="buy-list-item"
                v-for="(item, index) of activeCreditList"
                :key="index"
              >
                <div class="time">{{ item.name }}</div>
                <div class="old">￥{{ item.money_old }}</div>
                <div class="new">
                  <span class="unit">￥</span
                  ><span class="num">{{ item.money }}</span
                  ><span>{{i18n.Vip.each}}{{ item.credit_add }}{{i18n.Vip.text11}}</span>
                </div>
                <ul class="text-list">
                  <li>{{ creditTextList[0] }}</li>
                  <li>{{ creditTextList[1] }}</li>
                </ul>
                <div class="btn buy-btn" @click="goBuy(item)">{{i18n.Vip.purchase}}</div>
              </div>
            </div>
          </div>
          -->
        </div>
      </div>
      <div class="vip-new-equity">
        <h3>{{i18n.Vip.Membership}}</h3>
        <div class="list-one" v-for="(list, gindex) of oneList" :key="gindex">
          <div class="list-item" v-for="(item, index) of list" :key="index">
            <div class="item-title">
              <span>{{ index + 1 }}</span>
              <h4>{{ item.h1 }} <br />{{ item.h2 }}</h4>
            </div>
            <ul class="option">
              <li v-for="txt of item.list">
                {{ txt }}
              </li>
            </ul>
            <ul class="msg" v-if="item.msg">
              <li v-for="msg of item.msg">{{ msg }}</li>
            </ul>
          </div>
        </div>
        <div class="list-two" v-if="false">
          <div class="list-item" v-for="(item, index) of twoList" :key="index">
            <div class="img-content">
              <img :src="require(`@/assets/vip-new/${item.img}`)" />
            </div>
            <div class="text">
              <p class="main-text">{{ item.h1 }}</p>
              <p>
                {{ item.p }}
              </p>
            </div>
          </div>
        </div>
        <div class="list-three" v-if="false">
          <div
            class="list-item"
            v-for="(item, index) of threeList"
            :key="index"
          >
            <div class="text">
              <h4>{{ item.h1 }}</h4>
              <p class="main-text">{{ item.p }}</p>
              <ul>
                <li v-for="(text, textIndex) of item.list" :key="textIndex">
                  <span>{{ text.p1 }}</span
                  >{{ text.p2 }}
                </li>
              </ul>
            </div>
            <div class="img-content">
              <img :src="require(`@/assets/vip-new/${item.img}`)" />
            </div>
          </div>
        </div>
      </div>
      <div class="vip-new-question">
        <h3>{{i18n.Vip.Equity}}</h3>
        <div class="list-right">
          <ul
            v-for="(col, colIndex) of rightList"
            :key="colIndex"
            :class="{ active: colIndex == 2 }"
          >
            <li
              v-for="(item, index) of col"
              :key="`${index}_${item.name}`"
              :class="[item.type]"
            >
              <i v-if="item.icon" class="iconfont" :class="[item.icon]"></i>
              <span>{{ item.name }}</span>
              <img v-if="item.img" :src="item.img" alt="" />
            </li>
          </ul>
        </div>
      </div>
      <div class="vip-new-question">
        <h3>{{i18n.Vip.common}}</h3>
        <el-collapse>
          <el-collapse-item
            :title="item.name"
            :name="index"
            v-for="(item, index) of questionList"
            :key="index"
          >
            <div>{{ item.value }}</div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
    <OpenVip
      v-if="showDialog"
      :info="buyItem"
      :visible="showDialog"
      :creditList="creditList"
      @closeDialog="closeDialog"
      @successDialog="getUserInfo"
    />
    <CreditTips
      v-if="showTipsDialog"
      :visible="showTipsDialog"
      @closeDialog="closeTipsDialog"
    />
    <ActivateVip
      v-if="showVipDialog"
      :visible="showVipDialog"
      @closeDialog="closeVipDialog"
    />
    <FreeVip
      v-if="showFreeDialog"
      :visible="showFreeDialog"
      @closeDialog="closeFreeDialog"
    />
  </div>
</template>

<script>
import {
  apiUserInfo,
  apiVipMonthList,
  apiVipCreditList,
  apiVipFirstGet,
  apiVipFirstClose,
} from '@/api/api.js'
import {
  ONE_LIST,
  TWO_LIST,
  THREE_LIST,
  MONTH_TEXT_LIST,
  CREDIT_TEXT_LIST,
  QUESTION_LIST,
  RIGHTS_LIST,
} from './utils'
import OpenVip from './components/open-vip'
import ActivateVip from './components/activate-vip'
import CreditTips from './components/credit-tips'
import FreeVip from './components/free-vip'

export default {
  components: {
    OpenVip,
    CreditTips,
    ActivateVip,
    FreeVip,
  },
  computed: {
    i18n() {
      return this.$t('messages')
    },
  },
  data() {
    return {
      userInfo: {
        headimgurl: '',
        vip: {
          code: 0,
          status: '',
        },
      },
      loading: false,
      isvip: null,
      oneList: ONE_LIST,
      twoList: TWO_LIST,
      threeList: THREE_LIST,
      monthTextList: MONTH_TEXT_LIST,
      creditTextList: CREDIT_TEXT_LIST,
      questionList: QUESTION_LIST,
      rightList: RIGHTS_LIST,
      monthList: [],
      activeCreditList: [],
      creditList: [],
      showDialog: false,
      showTipsDialog: false,
      showVipDialog: false,
      showFreeDialog: false,
      buyItem: null,
    }
  },
  created() {
    this.getUserInfo()
    this.fecthVipMonthList()
    this.fecthVipCreditList()
  },
  methods: {
    goBuy(item) {
      this.buyItem = item
      this.showDialog = true
    },
    closeDialog() {
      this.showDialog = false
    },
    showTips() {
      this.showTipsDialog = true
    },
    showVip() {
      this.showVipDialog = true
    },
    showFree() {
      this.showFreeDialog = true
    },
    closeTipsDialog() {
      this.showTipsDialog = false
    },
    closeVipDialog() {
      this.showVipDialog = false
    },
    closeFreeDialog() {
      this.showFreeDialog = false
    },
    getUserInfo() {
      this.loading = true
      apiUserInfo()
        .then((res) => {
          this.userInfo = res.data
          if (this.userInfo.vip.code == 1) {
            this.isvip = true
          } else {
            this.isvip = false
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    fecthVipMonthList() {
      apiVipMonthList().then((res) => {
        this.monthList = res.data.map((item) => {
          return {
            payType: 1,
            ...item,
          }
        })
      })
    },
    fecthVipCreditList() {
      apiVipCreditList().then((res) => {
        this.creditList = res.data.map((item) => {
          return {
            payType: 2,
            ...item,
          }
        })
        this.activeCreditList = this.creditList.filter((item) => item.is_like)
      })
    },
    goRecord() {
      this.$router.push({ name: 'vipRecord', query: { type: 'buy' } })
    },
    closeFree() {
      apiVipFirstClose()
        .then()
        .finally(() => {
          setTimeout(() => {
            window.location.reload()
          }, 1300)

          const id = document.getElementById('vip-new-free-id')
          id.style.display = 'none'
        })
    },
  },
}
</script>

<style lang="scss">
.vip-new {
  padding-bottom: 50px;
  background-image: url(../../assets/vip-new/vip-bottom.png);
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 100%;
  .vip-new-content {
    margin: 25px auto 0;
    color: #1f1f40;
    font-size: 14px;
    .btn {
      display: inline-block;
      color: #fff;
      background: #3b86ff;
      cursor: pointer;
      border-radius: 5px;
      font-size: 14px;
      text-align: center;
      &:hover {
        opacity: 0.8;
      }
    }
    h3 {
      font-size: 30px;
      text-align: center;
    }
  }
  .vip-new-user {
    display: flex;
    align-items: center;
    position: relative;
    padding: 15px 48px;
    background-color: #f2f2f2;
    border-radius: 5px;
    &.vip {
      color: #fff;
      background-color: #4a4a58;
      .name {
        color: #e3c497;
      }
    }
    .vip-new-user-text {
      flex: 1;
    }
    .name {
      font-size: 24px;
      font-weight: bold;
    }
    .msg {
      margin-top: 10px;
    }
    .btns {
      text-align: right;
      .btn {
        margin-top: 22px;
      }
    }
    .vip-img {
      width: 23px;
      height: 23px;
      margin-left: 2px;
    }
    .header {
      width: 68px;
      height: 68px;
      border-radius: 50%;
      margin-right: 20px;
    }
    .vip-new-user-record {
      color: #848494;
      cursor: pointer;
    }
    .vip-new-user-activate {
    }
    .text {
      color: #848494;
      margin-top: 9px;
      margin-left: 32px;
      &:first-child {
        margin-left: 0;
      }
      i {
        margin-right: 5px;
      }
    }
    .btn {
      min-width: 63px;
      padding: 2px 5px;
      height: 22px;
      line-height: 22px;
      margin-left: 10px;
      font-size: 12px;
    }
  }
  .vip-new-free {
    position: relative;
    margin-top: 45px;
    border: 1px solid #ebebf2;
    opacity: 1;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 0 20px 12px;
    img {
      margin-right: 40px;
    }
    .icon-close {
      position: absolute;
      right: 14px;
      top: 18px;
      cursor: pointer;
      color: #d5d5d5;
    }
    .title {
      font-size: 18px;
      font-weight: bold;
      margin-top: 10px;
    }
    p {
      margin: 9px 0 20px;
    }
    .btn {
      min-width: 132px;
      padding: 2px 5px;
      height: 32px;
      line-height: 32px;
    }
  }
  .vip-new-buy-list {
    margin-top: 97px;
    h3 {
      margin-bottom: 62px;
    }
    .list {
      display: flex;
      justify-content: center;
    }
    .buy-list {
      display: flex;
    }
    .vip-list {
      padding: 28px 80px;
      background-color: #f6f6f6;
      border-radius: 10px;
    }
    .dot-list {
      .buy-list-item {
        background-image: url(../../assets/vip-new/vip-img11.png);
        background-repeat: no-repeat;
        background-position: right top;
      }
    }
    .icon-question-circle {
      cursor: pointer;
    }
    .buy-list-item {
      position: relative;
      width: 246px;
      height: 354px;
      background: #ffffff;
      box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      margin-right: 80px;
      text-align: center;
      padding: 40px 0;
      box-sizing: border-box;
      &:last-child {
        margin-right: 0;
      }
      &.active {
        border: 3px solid #ffc06a;
      }
    }
    .active-name {
      position: absolute;
      top: -17px;
      left: 61px;
      width: 92px;
      height: 32px;
      line-height: 32px;
      background: #ffc06a;
      border-radius: 5px;
      color: #fff;
    }
    .title {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 28px;
      i {
        font-size: 14px;
        color: #d5d5d5;
      }
    }
    .old {
      color: #bcbccb;
      text-decoration: line-through;
      margin: 16px 0 10px;
      min-height: 20px;
    }
    .new {
      margin-bottom: 20px;
      .unit {
        font-size: 24px;
        font-weight: bold;
      }
      .num {
        font-size: 42px;
        color: #3b86ff;
        font-weight: bold;
        margin-right: 5px;
      }
    }
    .text-list {
      padding: 0 20px 0 48px;
      text-align: left;
      li {
        position: relative;
        margin-bottom: 8px;
        &::before {
          position: absolute;
          left: -18px;
          top: 6px;
          display: inline-block;
          content: '';
          width: 6px;
          height: 6px;
          background: #ffc06a;
          border-radius: 50%;
        }
        span {
          color: #3b86ff;
        }
      }
    }
    .btn {
      width: 160px;
      height: 32px;
      line-height: 32px;
    }
  }
  .vip-new-equity {
    margin-top: 133px;
    .list-one {
      display: flex;
      justify-content: space-between;
      margin-top: 40px;
      .list-item {
        position: relative;
        padding: 30px;
        width: 286px;
        background: #ffffff;
        border: 2px solid #c1e6fe;
        box-shadow: 0px 10px 20px rgba(18, 40, 91, 0.15);
        border-radius: 10px;
        box-sizing: border-box;
        .option {
          > li {
            margin-left: 12px;
            font-size: 14px;
            position: relative;
            &:after {
              content: '';
              position: absolute;
              left: -12px;
              top: 7px;
              width: 6px;
              height: 6px;
              border-radius: 50%;
              background-color: #ffc06a;
            }
          }
        }
        .msg {
          > li {
            color: #bfbfbf;
            font-size: 12px;
            margin-left: 12px;
            margin-top: 4px;
          }
        }
      }
      .item-title {
        display: flex;
        align-items: center;
        > span {
          font-size: 60px;
          color: #c9e2ff;
        }
      }
      h4 {
        font-size: 18px;
        color: #3b86ff;
        margin-left: 14px;
      }
    }
    .list-two {
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 10px;
      .list-item {
        width: 50%;
        display: flex;
        align-items: center;
        margin-top: 30px;
      }
      .img-content {
        width: 55px;
        height: 55px;
        background: #ffffff;
        box-shadow: 0px 10px 30px rgba(18, 40, 91, 0.15);
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 16px;
      }
      .main-text {
        font-weight: bold;
        margin-bottom: 4px;
      }
    }
    .list-three {
      margin-top: 63px;
      .list-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;
        &:nth-child(2) {
          direction: rtl;
        }
      }
      img {
        margin-left: -30px;
      }
      h4 {
        font-size: 24px;
      }
      p {
        margin: 16px 0 19px;
        line-height: 22px;
      }
      .text {
        width: 446px;
      }
      ul {
        padding-left: 20px;
        color: #7c8084;
        span {
          margin-right: 10px;
        }
        li {
          position: relative;
          margin-bottom: 7px;
          &::before {
            position: absolute;
            left: -18px;
            top: 6px;
            display: inline-block;
            content: '';
            width: 6px;
            height: 6px;
            background: #7c8084;
            border-radius: 50%;
          }
        }
      }
    }
  }
  .list-right {
    display: flex;
    width: 926px;
    margin: 0 auto;
    > ul {
      flex: 1;
      padding: 40px 0;
      &.active {
        box-shadow: 0 0 30px rgba(18, 40, 91, 0.15);
        border-radius: 10px;
        > li {
          color: #1f1f40;
        }
      }
      &:first-child {
        > li {
          color: #1f1f40;
          justify-content: flex-start;
        }
      }
      > li {
        color: #9c9c9c;
        height: 70px;
        line-height: 70px;
        border-bottom: 1px solid #dcdcdc;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        &.title {
          font-size: 18px;
          color: #1f1f40;
          font-weight: bold;
        }
        > i {
          color: #36c626;
          display: inline-block;
          transform: scale(1.4);
        }
        > img {
          height: 20px;
          margin-left: 6px;
        }
      }
    }
  }
  .vip-new-question {
    min-height: 480px;
    margin-top: 130px;
    background-image: url(../../assets/vip-new/FAQ.png);
    background-repeat: no-repeat;
    background-position: left 26px;
    h3 {
      margin-bottom: 40px;
    }
    .el-collapse {
      border: none;
      width: 926px;
      margin: 0 auto;
      color: #1f1f40 !important;
    }
    .el-collapse-item__header {
      position: relative;
      padding-left: 30px;
      border: none;
      background: transparent;
      &::before {
        position: absolute;
        left: 0;
        top: 19px;
        display: inline-block;
        content: '';
        width: 9px;
        height: 9px;
        background: #3b86ff;
        border-radius: 50%;
      }
    }
    .el-collapse-item__wrap {
      border: none;
      background: transparent;
    }
    .el-collapse-item__content {
      padding-left: 30px;
    }
  }
}
</style>
