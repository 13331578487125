<template>
  <el-dialog
    class="activate-open-dialog"
    :title="$t('Vip.codeActived')"
    :visible.sync="localVisible"
    width="410px"
    :before-close="handleClose"
    :append-to-body="true"
  >
    <div class="activate-open-dialog-content">
      <h3>{{ $t('Vip.codeIpt') }}</h3>
      <el-input
        :placeholder="$t('Vip.codeIptTip')"
        v-model="code"
        class="input"
        size="samll"
      />
      <div class="fail" v-if="fail">{{ $t('Vip.codeError') }}</div>
    </div>
    <span slot="footer" class="dialog-footer">
      <div @click="vipactivate" class="btn" :class="{ disabled: disabled }">
        {{ $t('Vip.codeActived') }}
      </div>
    </span>
  </el-dialog>
</template>

<script>
import { apiVipCreditActive } from '@/api/api.js'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      code: '',
      showDialog: true,
      localVisible: this.visible,
      disabled: true,
      fail: false
    }
  },
  watch: {
    code() {
      this.fail = false
      if (this.code.length === 8) {
        this.disabled = false
      } else {
        this.disabled = true
      }
    }
  },
  methods: {
    handleClose() {
      this.$emit('closeDialog')
    },
    vipactivate() {
      this.fail = false
      apiVipCreditActive({
        active_code: this.code
      })
        .then((res) => {
          if (res.code == 200) {
            this.handleClose()
            this.$layer.msg(
              `<div class='success-icon'></div><div>${this.$t(
                'Vip.codeSuccess'
              )}</div>`
            )
            setTimeout(() => {
              window.location.reload()
            }, 1300)
          }
        })
        .catch(() => {
          this.fail = true
        })
    }
  }
}
</script>

<style lang="scss">
.activate-open-dialog {
  color: #1f1f40;
  .el-radio {
    margin-bottom: 20px;
  }
  .el-radio__inner {
    border: 1px solid #c3daff;
  }
  .el-radio__input.is-checked .el-radio__inner {
    border-color: #3b86ff;
    background: #fff;
    &::after {
      width: 8px;
      height: 8px;
      background: #3b86ff;
    }
  }
  .el-radio__input.is-checked + .el-radio__label {
    color: #4d4f5c;
  }
  .el-radio__label {
    color: #a4afb7;
  }
  .el-dialog__footer {
    text-align: center;
  }
  h3 {
    margin: 10px 0 15px;
  }
  .btn {
    display: inline-block;
    color: #fff;
    background: #3b86ff;
    margin-top: 30px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
    text-align: center;
    width: 100px;
    height: 32px;
    line-height: 32px;
    &.disabled {
      background: #bcbccb;
      cursor: not-allowed;
      pointer-events: none;
    }
    &:hover {
      opacity: 0.8;
    }
  }
  .fail {
    font-size: 12px;
    color: #ff606d;
    margin: 7px;
    position: absolute;
  }
  .el-dialog__header {
    display: flex;
    margin: 0 16px;
    justify-content: space-between;
    padding: 9px 0;
    border-bottom: 1px solid #ebebf2;
    align-items: center;
    .el-dialog__headerbtn {
      position: inherit;
    }
    .el-dialog__title {
      font-size: 14px;
      font-weight: bold;
      color: #a4afb7;
    }
  }
}
</style>
