<template>
  <el-dialog
    class="credit-tips-dialog"
    :title="$t('Vip.credit')"
    :visible.sync="localVisible"
    width="542px"
    :before-close="handleClose"
    :append-to-body="true"
  >
    <div class="credit-tips-dialog-content">
      <h3>{{ $t('Vip.creditUse') }}</h3>
      <div class="list-one">
        <div class="list-item" v-for="(item, index) of list" :key="index">
          <img :src="require(`@/assets/vip-new/${item.img}`)" />
          <h4>{{ item.h1 }}</h4>
          <p>{{ item.p1 }}<br />{{ item.p2 }}</p>
        </div>
      </div>
      <div class="text-list">
        <div class="title">{{ $t('Vip.creditUseTitle') }}</div>
        <div>1.{{ $t('Vip.creditText1') }}</div>
        <div>2.{{ $t('Vip.creditText2') }}</div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <div @click="handleClose" class="btn">{{ $t('Vip.creditOk') }}</div>
    </span>
  </el-dialog>
</template>

<script>
import { TIPS_LIST } from '../utils'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showDialog: true,
      localVisible: this.visible,
      list: TIPS_LIST
    }
  },
  methods: {
    handleClose() {
      this.$emit('closeDialog')
    }
  }
}
</script>

<style lang="scss">
.credit-tips-dialog {
  color: #1f1f40;
  .el-dialog__footer {
    text-align: center;
  }
  .btn {
    display: inline-block;
    color: #fff;
    background: #3b86ff;
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
    text-align: center;
    width: 160px;
    height: 32px;
    line-height: 32px;
    &:hover {
      opacity: 0.8;
    }
  }
  .el-dialog__header {
    display: flex;
    margin: 0 16px;
    justify-content: space-between;
    padding: 9px 0;
    border-bottom: 1px solid #ebebf2;
    align-items: center;
    .el-dialog__headerbtn {
      position: inherit;
    }
    .el-dialog__title {
      font-size: 14px;
      font-weight: bold;
      color: #a4afb7;
    }
  }
  h3 {
    font-size: 18px;
    font-weight: bold;
    margin-top: 10px;
  }
  .text-list {
    margin: 20px 0 0;
    line-height: 26px;
    .title {
      font-weight: bold;
    }
  }
  .list-one {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    .list-item {
      position: relative;
      padding: 0 20px;
      width: 139px;
      height: 134px;
      background: #ffffff;
      border: 2px solid #c1e6fe;
      box-shadow: 0px 10px 20px rgba(18, 40, 91, 0.15);
      border-radius: 10px;
      box-sizing: border-box;
      text-align: center;
    }
    img {
      position: absolute;
      left: 45px;
      top: -27px;
    }
    h4 {
      margin: 30px 0 8px;
      font-size: 18px;
      color: #3b86ff;
    }
    p {
      line-height: 20px;
    }
  }
}
</style>
