import i18n from '@/i18n/i18n'

export const ONE_LIST =
  i18n.locale == 'ch'
    ? [
        [
          {
            h1: '无限查看',
            h2: '高频真题讲解视频',
            list: [
              '重点难点题配合老师视频讲解',
              '内容涵盖答题技巧、高分模板、满分示例',
              '真正实现每一次练习都有名师陪伴，让题库“会说话”'
            ]
          },
          {
            h1: '无限使用',
            h2: '口语AI评分功能',
            list: [
              '会员可在口语部分题型（RA，RS）中无限使用AI评分功能',
              '帮助判定发音、流利度、内容准确度中存在的问题。',
              '非会员每天仅有5次AI评分次数'
            ]
          },
          {
            h1: '无限使用',
            h2: 'PTE真题随身听',
            list: [
              '各题型真题音频“歌单”随时洗脑播放',
              '随高频预测实时更新',
              '高效利用碎片时间加深真题音频记忆，锻炼语感。'
            ]
          },
          {
            h1: '无限查看',
            h2: '会员专属课程',
            list: [
              '课程板块设有会员专区，涵盖听说读写各题型精彩讲解及带练',
              '跟随名师指导视频，进行步骤拆分学习，边学边练，巩固成果，事半功倍。'
            ]
          }
        ],
        [
          {
            h1: '会员专享全仿真',
            h2: '模拟考试+分析',
            list: [
              '会员专享仿真PTE模考，模考题目涵盖本月最新高频真题，助你实时检测复习成果。',
              '完成模考后，还可联系小助手安排一对一模考分析'
            ]
          },
          {
            h1: '超前下载最新高频',
            h2: '预测PDF+音频',
            list: [
              'PTEGO每两周更新超高中题率的高频预测机经合辑PDF+配套音频文件',
              '不定期更新会员专属备考资料，供会员第一时间下载使用'
            ]
          },
          {
            h1: '优先使用',
            h2: '各地线下自习室',
            list: [
              'PTEGO在中国、澳洲多个城市设有线下自习室，配有巡视老师随时答疑，会员优先使用。'
            ],
            msg: ['自习室预约请联系微信：', 'FeifanEnglish006']
          },
          {
            h1: '每月免费',
            h2: '直播课/带练团',
            list: [
              'PTEGO每月开设名师直播课以及内容丰富的各题型带练打卡团',
              '加入打卡团群组，在老师督促下，和小伙伴们一起冲刺，克服拖延、懒惰'
            ]
          }
        ]
      ]
    : [
        [
          {
            h1: 'Unlimited viewing',
            h2: 'High-frequency real question explanation video',
            list: [
              'Key and difficult questions with teacher video explanation',
              'The content covers answering techniques, high score templates, and examples of full marks',
              'Realize that every exercise is accompanied by a famous teacher, so that the question bank "talks"'
            ]
          },
          {
            h1: 'Unlimited use',
            h2: 'Spoken AI scoring function',
            list: [
              'Members can use the AI ​​scoring function unlimitedly in the oral questions (RA, RS)',
              'Help to determine the problems in pronunciation, fluency, and content accuracy. ',
              'Non-members only have 5 AI ratings per day'
            ]
          },
          {
            h1: 'Unlimited use',
            h2: 'PTE Real Test Walkman',
            list: [
              'The real audio "song list" of each question type is brainwashed and played at any time',
              'Update in real time with high frequency forecast',
              'Efficient use of fragmented time to deepen the audio memory of the real questions and exercise the sense of language. '
            ]
          },
          {
            h1: 'Unlimited viewing',
            h2: 'Member Exclusive Course',
            list: [
              'The course section has a member area, covering wonderful explanations and exercises of various types of questions in listening, speaking, reading, and writing',
              'Follow the guidance video of a famous teacher, carry out step-by-step learning, learn while practicing, consolidate the results, and get twice the result with half the effort. '
            ]
          }
        ],
        [
          {
            h1: 'Members exclusive full simulation',
            h2: 'Mock exam + analysis',
            list: [
              'Members enjoy a simulated PTE mock test. The mock test questions cover the latest high-frequency real questions this month to help you check and review the results in real time. ',
              'After completing the mock test, you can also contact the assistant to arrange a one-to-one mock test analysis.'
            ]
          },
          {
            h1: 'Download the latest high frequency ahead of time',
            h2: 'Predictive PDF+Audio',
            list: [
              'PTEGO updates the high-frequency prediction machine compilation PDF + supporting audio files of the super high school question rate every two weeks',
              "Update members' exclusive exam preparation materials from time to time, for members to download and use in the first time"
            ]
          },
          {
            h1: 'Priority use',
            h2: 'Local offline study rooms',
            list: [
              'PTEGO has offline study rooms in many cities in China and Australia, equipped with inspection teachers to answer questions at any time, and members have priority to use. '
            ],
            msg: [
              'Please contact WeChat for appointment of self-study room:',
              'FeifanEnglish006'
            ]
          },
          {
            h1: 'monthly free',
            h2: 'Live class/with training group',
            list: [
              'PTEGO offers live lectures with famous teachers every month and a punch-in group with various question types with rich content',
              'Join the punching team group, under the supervision of the teacher, sprint with friends to overcome procrastination and laziness'
            ]
          }
        ]
      ]

export const TWO_LIST =
  i18n.locale == 'ch'
    ? [
        {
          img: 'vip-img4.png',
          h1: '解锁一课一练',
          p: '跟随名师指导视频进行步骤拆分学习，边学边练，巩固成果。'
        },
        {
          img: 'vip-img6.png',
          h1: '解锁真题随身听',
          p: '真题音频PTE“歌单”洗脑循环播放，帮助记忆，锻炼语感。'
        },
        {
          img: 'vip-img5.png',
          h1: '无限使用线下自习室',
          p: 'PTEGO在多个城市设有线下自习室，配有巡视老师随时答疑。'
        },
        {
          img: 'vip-img7.png',
          h1: '免费30分钟一对一模考分析',
          p: '免费预约名师30分钟一对一模考分析，精准定位问题，明确备考方向。'
        }
      ]
    : [
        {
          img: 'vip-img4.png',
          h1: 'Unlock one lesson and one practice',
          p:
            'Follow the guide video of a famous teacher to split the learning step by step, learn while practicing, and consolidate the results. '
        },
        {
          img: 'vip-img6.png',
          h1: 'Unlock the real test walkman',
          p:
            'Real-item audio PTE "song list" brainwashing loop playback to help memory and exercise the sense of language. '
        },
        {
          img: 'vip-img5.png',
          h1: 'Unlimited use of offline study room',
          p:
            'PTEGO has offline study rooms in many cities, with visiting teachers to answer questions at any time. '
        },
        {
          img: 'vip-img7.png',
          h1: 'Free 30-minute one-to-one model test analysis',
          p:
            'A free 30-minute one-on-one model test analysis for a famous teacher, accurately locates the problem, and clarifies the direction of preparation for the test. '
        }
      ]

export const THREE_LIST =
  i18n.locale == 'ch'
    ? [
        {
          img: 'vip-img8.png',
          h1: '会员专属精讲视频',
          list: [
            { p1: '加深记忆', p2: '跟着老师讲解练习，记得更牢' },
            { p1: '解决疑难', p2: '哪里不会点哪里，随时解决做题困惑' },
            { p1: '高效刷题', p2: '无脑刷题，名师直接讲给你听' }
          ],
          p:
            '以上高频机经均配有题目讲解视频，并持续更新中。内容涵盖解题技巧、作答思路、答案解析，大幅提升你的刷题质量'
        },
        {
          img: 'vip-img9.png',
          h1: '会员专属直播课',
          list: [
            { p1: '双周直播', p2: '精选课题，干货满满，听说读写全覆盖' },
            { p1: '改错答疑', p2: '一针见血找问题，备考不跑偏' },
            { p1: '陪伴督促', p2: '老师全程陪伴打卡，帮你克服懒惰' }
          ],
          p:
            '每月会员独享直播课，每期帮你冲破一个备考难关。精心选题的直播内容结合课下打卡互动、日常答疑，好评率100%'
        },
        {
          img: 'vip-img10.png',
          h1: '加入社群一起学',
          list: [
            { p1: '交流学习', p2: '和大神学霸共同进步' },
            { p1: '互相鼓励', p2: '和小伙伴一起学习，摆脱焦虑负能量' },
            { p1: '共享资料', p2: '考场回忆、新题收集、考位信息，一手掌握' }
          ],
          p:
            'PTEGO的学习群聚集了众多备考小伙伴，群内大神、老师出没，是大家交流学习，互相鼓励、第一时间共享学习信息和资料的场所'
        }
      ]
    : [
        {
          img: 'vip-img8.png',
          h1: "Members' exclusive lecture video",
          list: [
            {
              p1: 'Deepen memory',
              p2:
                'Follow the teacher to explain the exercises, remember more firmly'
            },
            {
              p1: 'Solve the problem',
              p2: "Where can't be clicked, please solve the puzzle at any time"
            },
            {
              p1: 'Efficient answering of questions',
              p2:
                'No-brained answering questions, the famous teacher will tell you directly'
            }
          ],
          p:
            'The above high-frequency machines are equipped with topic explanation videos, and they are being updated continuously. The content covers problem-solving skills, answering ideas, and answer analysis, which will greatly improve the quality of your brushing questions.'
        },
        {
          img: 'vip-img9.png',
          h1: 'Member Exclusive Live Class',
          list: [
            {
              p1: 'Biweekly live broadcast',
              p2:
                'Selected topics, full of dry goods, full coverage of listening, speaking, reading and writing'
            },
            {
              p1: 'Correcting mistakes and answering questions',
              p2:
                'Finding the problem on the nail on the head, preparing for the exam without going wrong'
            },
            {
              p1: 'Accompanying and urging',
              p2:
                'The teacher will accompany you throughout the process to help you overcome laziness'
            }
          ],
          p:
            'Monthly members have exclusive live broadcast classes, and each issue will help you overcome a difficulty in preparing for the exam. The live content of carefully selected topics is combined with the off-class check-in interaction, daily Q&A, and the praise rate is 100%'
        },
        {
          img: 'vip-img10.png',
          h1: 'Join the community and learn together',
          list: [
            {
              p1: 'Communication and learning',
              p2: 'Together with the great theological hegemony'
            },
            {
              p1: 'Encourage each other',
              p2:
                'Learn with friends to get rid of the negative energy of anxiety'
            },
            {
              p1: 'Share information',
              p2:
                'Exam room recall, new question collection, test position information, master it with one hand'
            }
          ],
          p:
            "PTEGO's learning group gathers many small friends for exam preparation. The great gods and teachers in the group are a place for everyone to exchange and learn, encourage each other, and share learning information and materials for the first time."
        }
      ]

export const MONTH_TEXT_LIST =
  i18n.locale == 'ch'
    ? ['无限查看高频讲解视频', '无限查看会员专属课程', '无限使用AI评分', '加送']
    : [
        'Unlimited viewing of high-frequency explanation videos',
        'Unlimited viewing of exclusive courses for members',
        'Unlimited use of AI scoring',
        'Additional'
      ]

export const CREDIT_TEXT_LIST =
  i18n.locale == 'ch'
    ? ['做题有疑惑？立刻使用点券向老师提问', '解锁站内丰富PTE课程']
    : [
        'Do you have doubts about the question? Immediately use the coupon to ask the teacher questions',
        'Unlock rich PTE courses in the station'
      ]

export const RIGHTS_LIST =
  i18n.locale == 'ch'
    ? [
        [
          {
            name: '',
            type: 'title'
          },
          {
            name: '查看高频真题讲解视频'
          },
          {
            name: '口语AI评分'
          },
          {
            name: 'PTE真题音频随身听'
          },
          {
            name: '会员专属课程'
          },
          {
            name: '全仿真模考'
          },
          {
            name: '每月高频预测'
          },
          {
            name: '直播课/打卡团'
          }
        ],
        [
          {
            name: '非会员',
            type: 'title'
          },
          {
            name: 'x'
          },
          {
            name: '每天限5次'
          },
          {
            name: 'x'
          },
          {
            name: 'x'
          },
          {
            name: '可使用免费版'
          },
          {
            name: '可下载往期'
          },
          {
            name: '可参加免费公开课'
          }
        ],
        [
          {
            name: '会员',
            type: 'title',
            img:
              'https://ptegofeifan.oss-accelerate.aliyuncs.com/go/admin_file_1618803628722635161920.png'
          },
          {
            name: '无限使用',
            icon: 'icon-checked'
          },
          {
            name: '无限使用',
            icon: 'icon-checked'
          },
          {
            name: '无限使用',
            icon: 'icon-checked'
          },
          {
            name: '无限查看',
            icon: 'icon-checked'
          },
          {
            name: '最新高频真题版',
            icon: 'icon-checked'
          },
          {
            name: '最新高频PDF+音频',
            icon: 'icon-checked'
          },
          {
            name: '会员专属公开课',
            icon: 'icon-checked'
          }
        ]
      ]
    : [
        [
          {
            name: '',
            type: 'title'
          },
          {
            name: 'View high-frequency real question explanation video'
          },
          {
            name: 'Spoken AI score'
          },
          {
            name: 'PTE Real Audio Walkman'
          },
          {
            name: 'Member Exclusive Course'
          },
          {
            name: 'Full simulation test'
          },
          {
            name: 'Monthly high frequency forecast'
          },
          {
            name: 'Live Class/Check-in Group'
          }
        ],
        [
          {
            name: 'Non-member',
            type: 'title'
          },
          {
            name: 'x'
          },
          {
            name: 'Limited to 5 times a day'
          },
          {
            name: 'x'
          },
          {
            name: 'x'
          },
          {
            name: 'Free version available'
          },
          {
            name: 'downloadable past issues'
          },
          {
            name: 'Can participate in free public classes'
          }
        ],
        [
          {
            name: 'Member',
            type: 'title',
            img:
              'https://ptegofeifan.oss-accelerate.aliyuncs.com/go/admin_file_1618803628722635161920.png'
          },
          {
            name: 'Unlimited use',
            icon: 'icon-checked'
          },
          {
            name: 'Unlimited use',
            icon: 'icon-checked'
          },
          {
            name: 'Unlimited use',
            icon: 'icon-checked'
          },
          {
            name: 'Unlimited viewing',
            icon: 'icon-checked'
          },
          {
            name: 'The latest high-frequency real version',
            icon: 'icon-checked'
          },
          {
            name: 'Latest high frequency PDF+audio',
            icon: 'icon-checked'
          },
          {
            name: 'member exclusive public class',
            icon: 'icon-checked'
          }
        ]
      ]

export const QUESTION_LIST =
  i18n.locale == 'ch'
    ? [
        {
          name: '网页端和APP端的会员和练习记录是通用的吗？',
          value:
            '是通用的。同一账号下购买的会员，点券和课程等付费产品，在网页端和APP端将自动同步并使用。所有练习记录也将同步。'
        },
        {
          name: '为什么网页端、APP端价格会有不同？',
          value:
            'IOS用户在应用商店购买时会收取一定手续费用，网页端会员金额不包含该手续费用。'
        },
        {
          name: '点券是什么，如何使用？',
          value:
            '点券是PTEGO平台内的“通用货币”。点券可以用来请老师批改作业，RA纠音等。也可以用来购买在线课程。不同题型批改，不同课程消耗点券数不同。详情可见提问或课程处。注意，点券不可以用来购买会员，但购买会员会赠送相应点券，详见会员介绍页面。'
        },
        {
          name: '购买会员、点券能退款吗？',
          value: 'PTEGO学习平台会员和点券均属于虚拟产品，一经出售不接受退款。'
        }
      ]
    : [
        {
          name:
            'Is the membership and practice records common on the web and APP? ',
          value:
            'Is universal. Paid products such as members, coupons and courses purchased under the same account will be automatically synchronized and used on the web and APP. All practice records will also be synchronized. '
        },
        {
          name: 'Why are the prices on the web and APP different? ',
          value:
            'IOS users will be charged a certain processing fee when purchasing in the app store, and the amount of the web-side membership does not include the processing fee. '
        },
        {
          name: 'What is the coupon and how to use it? ',
          value:
            'Tickets are the "universal currency" in the PTEGO platform. Vouchers can be used to ask teachers to correct homework, RA corrections, etc. It can also be used to purchase online courses. Different question types are corrected, and different courses consume different points. For details, please refer to the Questions or Course Office. Note that coupons cannot be used to purchase members, but the purchase of members will give corresponding coupons. For details, please refer to the member introduction page. '
        },
        {
          name: 'Can I get a refund for membership purchases and coupons? ',
          value:
            'PTEGO learning platform members and coupons are virtual products, and no refunds are accepted once they are sold. '
        }
      ]

export const TIPS_LIST =
  i18n.locale == 'ch'
    ? [
        {
          img: 'd-1.png',
          h1: '随时提问',
          p1: '名师即时答疑',
          p2: '解决做题困惑'
        },
        {
          img: 'd-2.png',
          h1: '围观讲解',
          p1: '自由解锁查看',
          p2: '感兴趣的问答'
        },
        {
          img: 'd-3.png',
          h1: '解锁课程',
          p1: '购买PTEGO',
          p2: '站内精彩课程'
        }
      ]
    : [
        {
          img: 'd-1.png',
          h1: 'Ask any time',
          p1: 'Famous teacher answers questions instantly',
          p2: 'Solve the puzzle of doing the problem'
        },
        {
          img: 'd-2.png',
          h1: 'Onlookers explain',
          p1: 'Free unlock to view',
          p2: 'Interested Q&A'
        },
        {
          img: 'd-3.png',
          h1: 'Unlock course',
          p1: 'Buy PTEGO',
          p2: 'Excellent course in the station'
        }
      ]
